import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonIcon, IonLabel, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonInput, IonBackButton, IonButtons, IonButton, IonLoading, IonAlert } from "@ionic/react";
import {person, lockClosed, chevronBack, mail, call } from "ionicons/icons";
import {useEffect, useState } from "react";
import './Tab1.css';
import React from 'react';

// import { AppContext} from '../Services/State.jsx';
import axios from "axios";

const Profile: React.FC<any> = ()=>{
    interface AlertTypeInterface {
        header: string;
        subHeader: string;
        message: string;
        buttons: any;
        showAlert: boolean;
    }
    // const { state } = useContext(AppContext);
    const [showLoadingState, setShowLoading] = useState({showLoadingMessage:'c...', showLoading: false});
    const [showAlertState, setShowAlert] = useState<AlertTypeInterface>({header: "", subHeader: "", message: "", buttons: [], showAlert: false});
    const [personalProfile, setMyProfile] = useState<any>([]);

    // var firstname = "";
    // var lastname = "";
    // var email = "";
    // var phone = "";
    // var location = "";
    // if (state.auth.user) {
    //     if(state.auth.user.firstname){
    //         firstname = state.auth.user.firstname;
    //     }
    //     if(state.auth.user.lastname){
    //         lastname = state.auth.user.lastname;
    //     }
    // }
    // var personProfile = {firstname: firstname,  lastname:lastname, email:email, phone:phone, location:location};
    // setMyProfile(personProfile);

    // var submitFunction = (e: any)=>{
    //     e.preventDefault();
    //     var url = e.target.action;
    //     const config = {
    //         headers: {
    //             Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODUwMDQ2MDgsImlzcyI6Imh0dHBzOlwvXC9tb250eXdvb2QuY28uemEiLCJhdWQiOiJodHRwczpcL1wvbW9udHl3b29kLmNvLnphIiwidXNlcklEIjoiMiJ9.gdzEaycRiguoiGTEK-4aeVQPFlmOOKNVxugJgpSXIO8",
    //             "Content-Type": "multipart/form-data"
    //         }
    //     };
    //     var orderForm = new FormData(e.target);
    //     orderForm.append("appType", "music");
      
    //     setShowLoading({showLoadingMessage: "Submitting...", showLoading: true});
    //     axios.post(url, orderForm, config).then(res=>{
    //         return res.data;
    //     }).then(response=>{
    //         console.log(response);
    //         setTimeout(() => {
    //             setShowLoading({...showLoadingState, showLoading: false});
    //         }, 900);
    //         var buttonActions:any = [];
    //         var alertStateVars:any = {};
    //         if (response.success) {
    //             console.log(response.data)
    //             setMyProfile(response.data);
    //             buttonActions = [
    //                 {
    //                     text: 'Done',
    //                     handler: () => {
    //                         // setShowModal2(false);
    //                     }
    //                 }
    //             ];
    //             alertStateVars = {
    //                 header: response.msg,
    //                 subHeader: "", 
    //                 message: response.msg2,
    //                 buttons: buttonActions
    //             };
    //             setShowAlert({...alertStateVars, showAlert: true});
    //             // onDismiss('manager');
    //             // setShowModal2(false);
    //         } else {
    //             buttonActions = [
    //                 {
    //                     text: 'Cancel',
    //                     role: 'cancel',
    //                     cssClass: 'secondary',
    //                     handler: () => {
    //                         // setShowModal2(false);
    //                     }
    //                 },
    //                 {
    //                     text: 'Retry',
    //                     handler: () => {
    //                         setTimeout(() => {
    //                             submitFunction(e);
    //                         }, 500);
    //                     }
    //                 }
    //             ];
    //             alertStateVars = {
    //                 header: response.msg,
    //                 subHeader: "", 
    //                 message: response.msg2,
    //                 buttons: buttonActions
    //             };
    //             setShowAlert({...alertStateVars, showAlert: true});
    //         };
    //     }).catch(err=>{
    //         console.log(err.message);
    //         setTimeout(() => {
    //             setShowLoading({...showLoadingState, showLoading: false});
    //         }, 900);
    //         var buttonActions = [
    //             {
    //                 text: 'Cancel',
    //                 role: 'cancel',
    //                 cssClass: 'secondary',
    //                 handler: () => {
    //                     console.log('Confirm Cancel');
    //                 }
    //             },
    //             {
    //                 text: 'Retry',
    //                 handler: () => {
    //                     submitFunction(e);
    //                 }
    //             }
    //         ];
    //         var alertStateVars = {header: "Error encountered", subHeader: "", message: err.message, buttons: buttonActions};
    //         setTimeout(() => {
    //             setShowAlert({...alertStateVars, showAlert: true});
    //         }, 1001);
    //     })
    // }

    const accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2ODUwMDQ2MDgsImlzcyI6Imh0dHBzOlwvXC9tb250eXdvb2QuY28uemEiLCJhdWQiOiJodHRwczpcL1wvbW9udHl3b29kLmNvLnphIiwidXNlcklEIjoiMiJ9.gdzEaycRiguoiGTEK-4aeVQPFlmOOKNVxugJgpSXIO8";
    // if (state.auth.user) {
    //     if (state.auth.user.accessToken) {
    //     accessToken = state.auth.user.accessToken;
    //     }
    // };
    
    useEffect(()=>{
        (async function doSearch(){
            const config = {
              headers: {
                Authorization: "Bearer "+accessToken
              }
            };
            setShowLoading({showLoadingMessage: "Fetching profile...", showLoading: true});
            try {
                const result = await axios.get("backend/includes/user/userProfile.php?action=getProfile", config);
                console.log(result);
                const response = result.data;
                console.log(response);
                setTimeout(() => {
                    setShowLoading({ showLoadingMessage: "", showLoading: false });
                }, 900);
                if (response.success) {
                    const respInfo = response.data;
                    setMyProfile(respInfo);
                } else {
                    const buttonActions = [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                console.log('Handling the event');
                            }
                        },
                        {
                            text: 'Retry',
                            handler: () => {
                                doSearch();
                            }
                        }
                    ];
                    const alertStateVars = { header: response.msg2, subHeader: "", message: response.msg2, buttons: buttonActions };
                    setTimeout(() => {
                        setShowAlert({ ...alertStateVars, showAlert: true });
                    }, 1001);
                }
            } catch (error:any) {
                setTimeout(() => {
                    setShowLoading({ showLoadingMessage: "", showLoading: false });
                }, 900);
                const buttonActions_1 = [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            console.log('Confirm Cancel');
                        }
                    },
                    {
                        text: 'Retry',
                        handler: () => {
                            doSearch();
                        }
                    }
                ];
                const alertStateVars_1 = { header: "Error encountered", subHeader: "", message: error.message, buttons: buttonActions_1 };
                setTimeout(() => {
                    setShowAlert({ ...alertStateVars_1, showAlert: true });
                }, 1001);
                const personProfile = { firstname: "Lethu", lastname: "Ndabazandile", email: "", phone: "", location: "" };
                setMyProfile(personProfile);

                console.log(error.message);
            }
          
        })();   
    
    },[accessToken]);
    

    return (
        <IonPage>
            <IonButtons slot="start" className="myHead">
                <IonBackButton className="mtBackBtn" defaultHref="home" text="Back" icon={chevronBack} />
            </IonButtons>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Profile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">profile</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div>
                    <br/>
                    <br/>
            
                    <IonCard>
                        <form action="backend/includes/user/userProfile.php?action=updateProfile">
                        <input type="hidden" name="type" defaultValue="updateProfile"/>
                            <IonCardHeader>
                                <IonCardTitle>
                                    My Profile
                                </IonCardTitle>
                            </IonCardHeader>
                           
                            <IonCardContent className="cardContent">
                                <IonList className="cardContentList">
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >first name</IonLabel>
                                        <IonIcon className="iconPart" icon={person} />
                                        <IonInput style={{width: "87%"}} name="firstname" value={personalProfile.firstname} type="text" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Last name</IonLabel>
                                        <IonIcon className="iconPart" icon={person} />
                                        <IonInput style={{width: "87%"}} name="lastname" value={personalProfile.lastname} type="text" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems"> 
                                        <IonLabel position="floating" className="theLabel" >Email address</IonLabel>
                                        <IonIcon className="iconPart" icon={mail} />
                                        <IonInput style={{width: "87%"}} name="email" value={personalProfile.email} type="email" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Cellphone number</IonLabel>
                                        <IonIcon className="iconPart" icon={call} />
                                        <IonInput style={{width: "87%"}} name="phone" value={personalProfile.phone} type="number" className="theInput" required />
                                    </IonItem>
                                    {/* <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Your location</IonLabel>
                                        <IonIcon className="iconPart" icon={locate} />
                                        <IonInput style={{width: "87%"}} name="passwordChangeRepeat" value={personalProfile.location} type="password" className="theInput" required />
                                    </IonItem> */}
                                </IonList>
                            </IonCardContent>
                            <IonButton expand="full" type='submit'>Save</IonButton>
                        </form>
                    </IonCard>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle>
                                Reset password
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className="cardContent">
                            <form action="backend/includes/user/userProfile.php?action=resetPassword" >
                                <input type="hidden" name="type" defaultValue="resetPassWord" />
                                <IonList className="cardContentList">
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Old Password</IonLabel>
                                        <IonIcon className="iconPart" icon={lockClosed} />
                                        <IonInput style={{width: "87%"}} name="oldPassword" type="password" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >New Password</IonLabel>
                                        <IonIcon className="iconPart" icon={lockClosed} />
                                        <IonInput style={{width: "87%"}} name="password" type="password" className="theInput" required />
                                    </IonItem>
                                    <IonItem className="profileItems">
                                        <IonLabel position="floating" className="theLabel" >Repeat new password</IonLabel>
                                        <IonIcon className="iconPart" icon={lockClosed} />
                                        <IonInput style={{width: "87%"}} name="passwordRepeat" type="password" className="theInput" required />
                                    </IonItem>
                                </IonList>
                                <IonButton expand="full" type='submit'>Reset</IonButton>
                            </form>
                            {/* <IonList className="cardContentList">
                                <IonItem>
                                    <IonIcon icon={chatbubbles} />
                                    <IonLabel> About us</IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonIcon icon={helpCircle} />
                                    <IonLabel> Help</IonLabel>
                                </IonItem>
                            </IonList> */}
                        </IonCardContent>
                    </IonCard>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </IonContent>
            <IonLoading
                isOpen={showLoadingState.showLoading}
                onDidDismiss={() => setShowLoading({...showLoadingState, showLoading: false})}
                message={showLoadingState.showLoadingMessage}
            />
            <IonAlert
                isOpen={showAlertState.showAlert}
                onDidDismiss={() => setShowAlert({...showAlertState, showAlert: false})}
                header={showAlertState.header}
                subHeader={showAlertState.subHeader}
                message={showAlertState.message}
                buttons={showAlertState.buttons}
            />
        </IonPage>
      );

}
export default Profile;
